// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-gier-design-js": () => import("./../src/pages/a-gier-design.js" /* webpackChunkName: "component---src-pages-a-gier-design-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-baby-beau-belle-js": () => import("./../src/pages/baby-beau-belle.js" /* webpackChunkName: "component---src-pages-baby-beau-belle-js" */),
  "component---src-pages-cozyblue-handmade-js": () => import("./../src/pages/cozyblue-handmade.js" /* webpackChunkName: "component---src-pages-cozyblue-handmade-js" */),
  "component---src-pages-grdn-brooklyn-js": () => import("./../src/pages/grdn-brooklyn.js" /* webpackChunkName: "component---src-pages-grdn-brooklyn-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-sorelle-gallery-js": () => import("./../src/pages/sorelle-gallery.js" /* webpackChunkName: "component---src-pages-sorelle-gallery-js" */)
}

